//API
import api from './AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import { exportToExcel } from 'react-json-to-excel';

//Function to use everywhere and get settings
export async function getSettings() {
    try {
        const response = await api.get('/instelling/');
        return response.data;
    } catch (error) {}
}

//Function to call videotutorials API
export async function getVideoTutorials() {
    try {
        const response = await api.get('/videotutorial');
        return response.data;
    } catch (error) {}
}

//Function to add manual log
export async function addManualLog(event, value, offerteId) {
    event.preventDefault();
    try {
        const response = await api.post('/offertes/add-log/' + offerteId, {
            data: {
                log: value
            }
        });
        if (response.data) {
            return response.data.logs;
        }
    } catch (error) {}
}

//Set notification functions
export const notifyError = (text) => toast.error(text, {
    position: "top-center"
});
export const notifySuccess = (text) => toast.success(text, {
    position: "top-center"
});

//Functions for calculating time
export const getTimeFromSeconds = (input) => {
    var sec_num = parseInt(input, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours + ':' + minutes;
}

export const getTotalSecondsFromTimeString = (timeString) => {
    if (!timeString) {
        return 0;
    }
    var a = timeString.split(':');
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
    return seconds;
}

export const mapOveruren = (overuren) => {
    var seconds = 0;
    overuren.map((item, index) =>  {
        seconds += getTotalSecondsFromTimeString(item.diensturen_extra);
    })
    return seconds;
}

export const countTotalSeconds = (day) => {
    var seconds = getTotalSecondsFromTimeString(day.diensturen);
    seconds +=  mapOveruren(day.overuren);
    var secondsCorrectie = getTotalSecondsFromTimeString(day.correctie);
    return seconds - secondsCorrectie;
}

const parseNumericValue = (cellValue) => {
    cellValue = cellValue.replace(/,/g, '');
    var lastCommaIndex = cellValue.lastIndexOf(',');
    if (lastCommaIndex !== -1) {
        cellValue = cellValue.replace(/,/g, '');
        cellValue = cellValue.slice(0, lastCommaIndex) + '.' + cellValue.slice(lastCommaIndex + 1);
    }
    var numericValue = parseFloat(cellValue);
    return numericValue;
}

export const downloadCsv = (data, type, offertenummer) => {
    var fileName = 'export-prijslijst-' + type + '-' + offertenummer;
    exportToExcel(data, fileName);
}

export const downloadExcelLTL = (offerte, two) => {
    if (two) {
        var inhouden = 'rijen_inhouden_twee';
        var zones = 'zones_twee';
        var fileType = 'ltl-2';
    } else {
        var inhouden = 'rijen_inhouden';
        var zones = 'zones';
        var fileType = 'ltl';
    }
    const excelData = [];
    const objRijen = {};
    for (let i = 0; i < offerte[inhouden][0][0].items.length; i++) {
        objRijen[i] = '';
    }
    for (let i = 0; i < offerte[zones].length; i++) {
        objRijen[offerte[zones][i].naam] = offerte[zones][i].naam;
    }
    excelData[0] = objRijen;
    offerte[inhouden].map((item, index) =>  {
        const objRij = {};
        for (let i = 0; i < offerte[inhouden][0][0].items.length; i++) {
            objRij[i] = item[0].items[i];
        }
        excelData.push(objRij);
    })
    offerte[zones].map((item, index) =>  {
        const objKey = item.naam;
        item.prijzen.unshift([]);
        item.prijzen.map((subItem, subIndex) =>  {
            if (subIndex > 0) {
                if (subItem[0].value && subItem[0].value.includes("€")) {
                    var cellValue = subItem[0].value.replace(/€| /g, "");
                    cellValue = parseFloat(cellValue.replace(/\./g, "").replace(",", "."));
                } else {
                    var cellValue = subItem[0].value;
                }
                excelData[subIndex][objKey] = cellValue;
            }
        })
    })
    excelData[0][0] = 'Laadmeters';

    //If offerte is 2x ltl
    if (offerte.twee_ltl) {
        if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 2 && two) {
            excelData[0][1] = offerte.kolommen_tonen[1].code;
        }
        if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 2 && !two) {
            excelData[0][1] = offerte.kolommen_tonen[0].code;
        }
        if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 1) {
            excelData[0][1] = offerte.kolommen_tonen[0].code;
        }
        if (offerte.toon_gewicht) {
            if (offerte.kolommen_tonen) {
                excelData[0][2] = 'KG';
            } else {
                excelData[0][1] = 'KG';
            }
        }
    } else {
        if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 2) {
            excelData[0][1] = offerte.kolommen_tonen[0].code;
            excelData[0][2] = offerte.kolommen_tonen[1].code;
        }
        if (offerte.kolommen_tonen && offerte.kolommen_tonen.length === 1) {
            excelData[0][1] = offerte.kolommen_tonen[0].code;
        }
        if (offerte.toon_gewicht) {
            if (offerte.kolommen_tonen) {
                if (offerte.kolommen_tonen.length === 2) {
                    excelData[0][3] = 'KG';
                } else {
                    excelData[0][2] = 'KG';
                }
            } else {
                excelData[0][1] = 'KG';
            }
        }
    }
    //Add postcodes
    let maxLength = 0;
    let maxIndex = -1;
    for (let i = 0; i < offerte[zones].length; i++) {
        const postcodesLength = offerte[zones][i].postcodes.length;
        if (postcodesLength > maxLength) {
            maxLength = postcodesLength;
            maxIndex = i;
        }
    }
    var arrayPostcodesObj = [];
    for (let i = 0; i < offerte[zones][maxIndex].postcodes.length; i++) {
        var obj = {...excelData[1]};
        for (const [key, value] of Object.entries(obj)) {
            obj[key] = '';
        }
        arrayPostcodesObj.push(obj);
    }
    arrayPostcodesObj.map((item, index) => {
        const modifiedItem = { ...item };
        for (const key in item) {
            const offerteZonesIndex = offerte[zones].findIndex(zone => zone.naam === key);
            if (offerteZonesIndex > -1) {
                modifiedItem[key] = offerte[zones][offerteZonesIndex].postcodes[index] ? offerte[zones][offerteZonesIndex].postcodes[index].postcode : '';
            }
        }
        excelData.splice(index + 1, 0, modifiedItem)
        return modifiedItem;
    });
    downloadCsv(excelData, fileType, offerte.offertenummer);
}

export const downloadExcelFTL = (offerte) => {
    const excelData = [];
    const objRijen = {};
    for (let i = 0; i < offerte.prijzen_ftl.length; i++) {
        objRijen[i] = offerte.prijzen_ftl[i].prijzen[0][0].value;
    }
    excelData[0] = objRijen;
    offerte.prijzen_ftl.map((item, index) =>  {
        for (let i = 1; i < item.prijzen.length; i++) {
            if (item.prijzen[i][0].value && item.prijzen[i][0].value.includes("€")) {
                var cellValue = item.prijzen[i][0].value.replace(/€| /g, "");
                cellValue = parseFloat(cellValue.replace(/\./g, "").replace(",", "."));
            } else {
                var cellValue = item.prijzen[i][0].value;
            }
            if (excelData[i]) {
                excelData[i][index] = cellValue;
            } else {
                excelData[i] = {0: cellValue};
            }
        }
    })
    downloadCsv(excelData, 'ftl', offerte.offertenummer);
}

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    const copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = deepCopy(obj[key]);
        }
    }
    return copy;
}

export const formatCurrency = (input) => {
    if (typeof input === 'number') {
      const roundedValue = Math.round(input * 100) / 100;
      return '€ ' + roundedValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else if (typeof input === 'string') {
      // Check if the string contains any letters
      if (/[^0-9.,]/.test(input)) {
        return input; // Return input as is
      }
      const numericValue = parseFloat(input.replace(/[^\d,.]/g, '').replace(',', '.'));
      if (!isNaN(numericValue)) {
        return '€ ' + numericValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return input;
      }
    } else {
      return input;
    }
}