const InputField = (props) => {
    return (
        <div className="input-field">
            <input 
                type={props.section.fields[props.field].type} 
                placeholder={props.section.fields[props.field].label + (props.section.fields[props.field].required ? '*' : '')}
                className={props.section.fields[props.field].value ? 'form-field valid' : 'form-field'} 
                value={props.section.fields[props.field].value} 
                onChange={(e) => props.handleChange(props.section, props.state, props.field, e.target.value)} 
                disabled={props.disabled ? props.disabled : props.isLoading}
            />
            {
                props.section.fields[props.field].value ?
                <span className="placeholder">{props.section.fields[props.field].label + (props.section.fields[props.field].required ? '*' : '')}</span>
                :
                null
            }
            
        </div>
    );
};
export default InputField;