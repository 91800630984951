import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { ThreeDots } from  'react-loader-spinner';
import Spreadsheet from "react-spreadsheet";
import { Tooltip } from 'react-tooltip';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './prices.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const Prijslijst = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [params, setParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const offerteId = params.get("id");
    const ltlVersion = params.get("ltl");
    const [offerte, setOfferte] = useState([]);
    const [offerteNummer, setOfferteNummer] = useState('');
    const [ladingSoorten, setLadingSoorten] = useState([]);
    const [columnLabels, setColumnLabels] = useState([]);
    const [labelMain, setLabelMain] = useState('');
    const [tableRows, setTableRows] = useState([]);
    const [labelRowTwo, setLabelRowTwo] = useState('');
    const [labelRowThree, setLabelRowThree] = useState([]);
    const [labelRowsWeight, setLabelRowsWeight] = useState('KG');
    const [tableRowsWeight, setTableRowsWeight] = useState([]);
    const [rowColumnsClass, setRowColumnsClass] = useState('one');
    const [rijenInhouden, setRijenInhouden] = useState([]);
    const [zones, setZones] = useState([]);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [editableIndexes, setEditableIndexes] = useState([4]);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const returnValueWithEenheid = (value, eenheid) => {
        if (eenheid === 'laadmeters' || eenheid === 'laadmeters_custom') {
            return '= ' + value.laadmeters + ' m';
        }
        else if (eenheid === 'gewicht') {
            return '';
        } else {
            return ''
        }
    }

    //Define rows
    const returnRows = (ladingtype, min, max, step) => {
        const rows = [];
        for(let i = min; i < max; i += step) {
            const value = { value: Number(i).toFixed(2) };
            rows.push(value);
        }
        setLabelMain(ladingtype.afkorting);
        setTableRows(rows);
    }

    const returnExtraRow = (kolommen, index, state, min, max, step) => {
        state(kolommen[index].code);
        const rows = [];
        for(let i = min; i < max; i += step) {
            const result = Number(i).toFixed(2) / kolommen[index].laadmeters;
            const roundedResult = Math.round(result);
            if (result === roundedResult) {
                rows.push({ value: result });
            } else {
                rows.push({ value: '' });
            }
        }
        return rows;
    }

    const returnPriceRows = (totalRows) => {
        const rows = [];
        for (let a = 0; a < totalRows; a++) {
            rows.push([{ "value": "" }]);
        }
        return rows;
    }

    const returnWeightRows = (laadmeter_gewicht, min, max, step) => {
        const rows = [];
        for(let i = min; i < max; i += step) {
            rows.push({ "value": Number(laadmeter_gewicht * i).toFixed(0)});
        }
        return rows;
    }

    //Set the rows for totalen
    const setPrijzenRows = (zones, min, max, step) => {
        let totalRows = 0;
        var longest = 0;
        for(let i = min; i < max; i += step) {
            totalRows += 1;
        }
        zones.map((item, index) =>  {
            if (item.prijzen === null) {
                item.prijzen = returnPriceRows(totalRows);
            }
            if (item.postcodes.length > longest) {
                longest = item.postcodes.length;
            }
        })
        setHeaderHeight(30 * longest);
        setZones(zones);
    }


    const defineColumnLabels = (ladingtype, kolommen_tonen, rijen_inhouden, toon_gewicht, twee_ltl, ltlVersion) => {
        const arrLabels = [];
        arrLabels.push(ladingtype.afkorting);
        if (twee_ltl) {
            if (ltlVersion === '2') {
                if (kolommen_tonen.length === 2) {
                    arrLabels.push(kolommen_tonen[1].code);
                }
            } else {
                arrLabels.push(kolommen_tonen[0].code);
            }
        } else {
            kolommen_tonen.map((item, index) =>  {
                arrLabels.push(item.code);
            })
        }
        if (ladingtype.key !== 'gewicht' && toon_gewicht) {
            arrLabels.push('KG');
        }
        setColumnLabels(arrLabels);
    }

    const getOfferte = async () => {
        try {
            const response = await api.get('/offertes/' + offerteId);
            if (response.data) {
                setOfferte(response.data);
                setOfferteNummer(response.data.offertenummer);
                setRijenInhouden(ltlVersion === '2' ? response.data.rijen_inhouden_twee : response.data.rijen_inhouden);
                const arrSoorten = [];
                response.data.ladingsoorten.map((item, index) =>  {
                    arrSoorten.push(item.code + ' ' + returnValueWithEenheid(item, response.data.ladingtype.key));
                })
                setLadingSoorten(arrSoorten);
                defineColumnLabels(response.data.ladingtype, response.data.kolommen_tonen, response.data.rijen_inhouden, response.data.toon_gewicht, response.data.twee_ltl, ltlVersion);

                returnRows(response.data.ladingtype, response.data.minimale_inhoud, response.data.maximale_inhoud, response.data.stappen);
                if (response.data.kolommen_tonen.length > 0) {
                    returnExtraRow(response.data.kolommen_tonen, 0, setLabelRowTwo, response.data.minimale_inhoud, response.data.maximale_inhoud, response.data.stappen);
                }

                let columns = 1;
                if (response.data.ladingtype.key !== 'gewicht' && response.data.toon_gewicht) {
                    setTableRowsWeight(returnWeightRows(response.data.laadmeter_gewicht, response.data.minimale_inhoud, response.data.maximale_inhoud, response.data.stappen));
                    columns += 1;
                }
                if (response.data.twee_ltl) {
                    if (response.data.kolommen_tonen.length > 0) {
                        columns += 1;
                    }
                } else {
                    if (response.data.kolommen_tonen.length === 1) {
                        columns += 1;
                    }
                    if (response.data.kolommen_tonen.length === 2) {
                        columns += 2;
                    }
                }
                
                if (columns === 2) {
                    setRowColumnsClass('two');
                }
                if (columns === 3) {
                    setRowColumnsClass('three');
                }
                if (columns === 4) {
                    setRowColumnsClass('four');
                }
                 
                setPrijzenRows(ltlVersion === '2' ? response.data.zones_twee : response.data.zones, response.data.minimale_inhoud, response.data.maximale_inhoud, response.data.stappen);
                setIsLoading(false);
            }
        } catch (error) {}
    }

    function formatCurrency(input) {
        if (typeof input === 'number') {
          const roundedValue = Math.round(input * 100) / 100;
          return '€ ' + roundedValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else if (typeof input === 'string') {
          // Check if the string contains any letters
          if (/[^0-9.,]/.test(input)) {
            return input; // Return input as is
          }
          const numericValue = parseFloat(input.replace(/[^\d,.]/g, '').replace(',', '.'));
          if (!isNaN(numericValue)) {
            return '€ ' + numericValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            return input;
          }
        } else {
          return input;
        }
    }
    
    const changeZones = (values, index) => {
        const current = [...zones];
        const prijzenLength = current[index].prijzen.length;
        const currentPrices = [...zones[index].prijzen];
        const differingIndexes = [];
        for (let i = 0; i < values.length; i++) {
            if (values[i] && currentPrices[i]) {
                if (values[i][0].value !== currentPrices[i][0].value) {
                    differingIndexes.push(i);
                }
            }
        }
        if (differingIndexes.length > 1) {
            if (values[differingIndexes[differingIndexes.length - 1]][0].value === '') {
                values[differingIndexes[differingIndexes.length - 1]][0].value = currentPrices[differingIndexes[differingIndexes.length - 1]][0].value;
            }
        }
        current[index].prijzen = values;
        if (current[index].prijzen.length > prijzenLength) {
            let elementsToRemove = current[index].prijzen.length - prijzenLength;
            if (elementsToRemove > 0) {
                current[index].prijzen = current[index].prijzen.slice(0, -elementsToRemove);
            }
        }
        setZones(current);
    }

    const addManualRow = (index) => {
        const currentRijenInhouden = [...rijenInhouden];
        const arrayNewItems = [
            {
                editable: true,
                items: []
            }
        ];
        for (let i = 0; i < currentRijenInhouden[0][0].items.length; i++) {
            arrayNewItems[0].items.push('');
        }
        currentRijenInhouden.splice(index, 0, arrayNewItems);
        setRijenInhouden(currentRijenInhouden);
        setZones((prevZones) => {
          const newZones = [...prevZones];
          newZones.forEach((zone) => {
            const newPrijzen = [...zone.prijzen];
            newPrijzen.splice(index, 0, [{ value: '' }]);
            zone.prijzen = newPrijzen;
          });
          return newZones;
        });
    };

    const removeManualRow = (index) => {
        const currentRijenInhouden = [...rijenInhouden];
        currentRijenInhouden.splice(index, 1);
        setRijenInhouden(currentRijenInhouden);
        setZones((prevZones) => {
            const newZones = [...prevZones];
            newZones.forEach((zone) => {
                const newPrijzen = [...zone.prijzen];
                newPrijzen.splice(index, 1);
                zone.prijzen = newPrijzen;
            });
            return newZones;
        });
    }

    const changeRijInhoud = (value, index, subIndex) => {
        const currentRijenInhouden = [...rijenInhouden];
        currentRijenInhouden[index][0].items[subIndex] = value;
        setRijenInhouden(currentRijenInhouden);
    }

    const handleFormatting = () => {
        const current = [...zones];
        current.forEach(zone => {
            zone.prijzen = zone.prijzen.map(item => {
            if (Array.isArray(item) && item.length > 0 && item[0].value) {
                const formattedValue = formatCurrency(item[0].value);
                return [{ value: formattedValue }];
            } else {
                return item;
            }
            });
        });
        return current;
    };

    const savePrices = async () => {
        setIsSaving(true);
        await delay(1000);
        const log = {
            "omschrijving": 'Prijzen bijgewerkt door ' + medewerker.voornaam + ' ' + medewerker.achternaam,
            "datum_tijd": new Date().toISOString()
        };
        offerte.logs.unshift(log);
        const formattedZones = handleFormatting();
        const dataObj = {
            minimale_inhoud: offerte.minimale_inhoud,
            maximale_inhoud: offerte.maximale_inhoud,
            stappen: offerte.stappen,
            logs: offerte.logs
        };
        if (ltlVersion === '2') {
            dataObj.rijen_inhouden_twee = rijenInhouden;
            dataObj.zones_twee = formattedZones;
        } else {
            dataObj.rijen_inhouden = rijenInhouden;
            dataObj.zones = formattedZones;
        }
        try {
            const response = await api.post('/offertes/prices/' + offerteId , {
                data: dataObj
            });
            if (response.data) {
                setIsSaving(false);
                notifySuccess('Prijzen zijn opgeslagen');
            }
        } catch (error) {}
    }
    
    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOfferte();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/prijslijst" />
        <div className="dashboard-content">
            <DashboardHeader title={ltlVersion === '2' ? 'Prijslijst 2 LTL' : 'Prijslijst LTL'} />
            
            <div className="prices-table">
                <div className="table-header">
                    
                </div>
            </div>

            <div className="table-contents">
                <div className="table-rows">
                    <div className="column-header" style={{height: headerHeight}}>
                        <div>
                            {
                                ladingSoorten.map((item, index) =>  {
                                    return <p key={index}>{item}</p>
                                })
                            }
                        </div>
                    </div>

                    {
                        columnLabels.length > 0 ?
                        <div className={'row-columns names ' + rowColumnsClass}>
                            <div className="table-row">
                            {
                                columnLabels.map((item, index) =>  {
                                    return <div key={index} className="table-row-item">
                                                <span>{item}</span>
                                            </div>
                                })
                            }
                            <Icon.PlusCircleFill size={20} id={'add-row-0'} className="add-row" onClick={() => { addManualRow(0); }} />
                            <Tooltip
                                anchorId={'add-row-0'}
                                place="top"
                                content="Rij hieronder invoegen"
                            />
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        rijenInhouden.length > 0 ?
                        <div className={'row-columns ' + rowColumnsClass}>
                            {
                                rijenInhouden.map((item, index) =>  {
                                    return <div key={index} className={'table-row ' + (editableIndexes.includes(index) ? 'editable' : '')}>
                                            <Icon.PlusCircleFill size={20} id={'add-row-' + index} className="add-row" onClick={() => { addManualRow(index + 1); }} />
                                            {
                                                item[0].editable ?
                                                <Icon.DashCircleFill size={20} id={'remove-row-' + index} className="remove-row" onClick={() => { removeManualRow(index); }} />
                                                :
                                                null
                                            }
                                            <Tooltip
                                                anchorId={'add-row-' + index}
                                                place="top"
                                                content="Rij hieronder invoegen"
                                            />
                                            {
                                                item[0].editable ?
                                                <Tooltip
                                                    anchorId={'remove-row-' + index}
                                                    place="top"
                                                    content="Rij verwijderen"
                                                />
                                                :
                                                null
                                            }
                                            
                                            {
                                                item[0].editable ?
                                                <>
                                                {
                                                    item[0].items.map((subItem, subIndex) => {
                                                        return <div key={subIndex} className="table-row-item">
                                                                <input type="text" value={subItem} onChange={(e) => changeRijInhoud(e.target.value, index, subIndex)} />
                                                            </div>
                                                    })
                                                }
                                                </>
                                                :
                                                <>
                                                {
                                                    item[0].items.map((subItem, subIndex) => {
                                                        return <div key={subIndex} className="table-row-item">
                                                                <span>{subItem}</span>
                                                            </div>
                                                    })
                                                }
                                                </>
                                            }
                                            
                                        </div>
                                })
                            }
                        </div>
                        :
                        null
                    }
                    

                    
                </div>
                <div className="table-prices">
                {
                    zones.map((item, index) =>  {
                        const columnWidth = 100 / zones.length;
                        return <div key={index} className="column" style={{width: columnWidth + '%'}}>
                                <div className="column-header" style={{height: headerHeight}}>
                                    <p style={{marginBottom: 20, color: '#CB0A32', height: 15}}>Zone {index + 1}</p>
                                    {
                                        item.postcodes.map((subItem, subIndex) =>  {
                                            return <p key={subIndex}>{subItem.postcode}</p>
                                        })
                                    }
                                </div>
                                <div className="label-cell"><p>Totaal</p></div>
                                <Spreadsheet data={item.prijzen} hideRowIndicators hideColumnIndicators onChange={(e) => changeZones(e, index)} />
                            </div>
                    })
                }
                </div>
                <div className={'saving-prices ' + (isSaving ? 'show' : '')}>
                    <div className="saving-content">
                        <ThreeDots 
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#001c43" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                        <p>Prijzen opslaan</p>
                    </div>
                </div>
            </div>

            <button onClick={savePrices} className="save">Prijzen opslaan</button>

            <Toaster />
        </div>
        <BottomNav page="/prijslijst" />
        <img src={vsdvArtwork} className="artwork" />
    </div>
    );
};
export default Prijslijst;