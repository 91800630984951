import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

//styles
import './header.css';

const DashboardHeader = (props) => {

    const navigate = useNavigate();
    const voornaam = props.voornaam;
    const title = props.title;

    const hour = Number(moment().format('H'));
    if (hour > 6 && hour < 12) {
        var greeting = 'Goedemorgen';
    }
    else if (hour > 11 && hour < 18) {
        var greeting = 'Goedemiddag';
    } else {
        var greeting = 'Goedenavond';
    }

    const logout = () => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('ingelogd');
        localStorage.removeItem('medewerker');
        navigate('/login');
    }

    return (
        <div id="header">
            {
                title ?
                <h1>{title}</h1>
                :
                <h1>{greeting} {voornaam}</h1>
            }
            <p className="logout" onClick={logout}><Icon.BoxArrowLeft /> Uitloggen</p>
        </div>
    );
};
export default DashboardHeader;