import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';

//Components
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//styles
import './menu.css';

const Menu = (props) => {

    //Get props
    const page = props.page;

    //Set variables
    const navigate = useNavigate();
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const loggedIn = localStorage.getItem('ingelogd');

    //Set navitems and for which user role
    const navitems = [
        {
            name: 'Dashboard',
            link: '/',
            icon: <Icon.House />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Nieuwe offerte',
            link: '/offerte',
            icon: <Icon.Receipt />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Openstaand',
            link: '/openstaand',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Te controleren',
            link: '/te-controleren',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'In afwachting',
            link: '/in-afwachting',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Afgewezen',
            link: '/afgewezen',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Goedgekeurd',
            link: '/goedgekeurd',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Voorbladen',
            link: '/voorbladen',
            icon: <Icon.ArrowRightShort />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Instellingen',
            link: '/instellingen',
            icon: <Icon.Gear />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
    ];

    //Logout function
    const logout = () => {
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('ingelogd');
        localStorage.removeItem('medewerker');
        navigate('/login');
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <div className="dashboard-content">
            <DashboardHeader title="Menu" />
            <div className="list-menu-items">
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type)) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                    <div className="logout-section">
                        <p className="logout-menu" onClick={logout}><Icon.BoxArrowLeft /> Uitloggen</p>
                    </div>
                </div>
            <Toaster />
        </div>
        <BottomNav page="/menu" />
    </div>
    );
};
export default Menu;