import { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { PDFViewer, BlobProvider, pdf, Document, View, Image, Page, Text, Font, PDFDownloadLink, StyleSheet, Svg, Polygon, G } from '@react-pdf/renderer';
import Select from 'react-select';
import ReactCrop from 'react-image-crop';
import Cropper from 'react-easy-crop'


//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import pdfHeader from '../../../assets/images/header-offerte-right.png';
import divisieImg from '../../../assets/images/voorblad.jpg';
import PrometoBold from '../../../assets/fonts/Prometo-Bold.ttf';
import PrometoMedium from '../../../assets/fonts/Prometo-Medium.ttf';
import PrometoMediumItalic from '../../../assets/fonts/Prometo-MediumItalic.ttf';
import PrometoRegular from '../../../assets/fonts/Prometo-Regular.ttf';
import PrometoLight from '../../../assets/fonts/Prometo-Light.ttf';
import PrometoLightItalic from '../../../assets/fonts/Prometo-LightItalic.ttf';

//styles
import './voorbladen.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-image-crop/dist/ReactCrop.css';

// Register font
Font.register({family: 'PrometoRegular', src: PrometoRegular });
Font.register({family: 'PrometoMedium', src: PrometoMedium });
Font.register({family: 'PrometoBold', src: PrometoBold });
Font.register({family: 'PrometoMediumItalic', src: PrometoMediumItalic });
Font.register({family: 'PrometoLight', src: PrometoLight });
Font.register({family: 'PrometoLightItalic', src: PrometoLightItalic });

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 35,
        fontSize: 12,
        color: '#001E4E'
    },
    imageSection: {
        height: 480
    },  
    headerLogo: {
        marginTop: -485,
        marginRight: -5
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 290,
        backgroundColor: '#EAF3F8',
        padding: 15
    },
    title: {
        fontFamily: 'PrometoBold',
        fontSize: 28,
        textTransform: 'uppercase',
        marginTop: 20
    },
    subtitle: {
        fontFamily: 'PrometoBold',
        fontSize: 20,
        marginBottom: 10
    },
    receiverInfo: {
        fontFamily: 'PrometoRegular'
    }
});


// Create Voorblad Preview Component
const VoorbladPreview = (props) => (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <View style={styles.imageSection}>
            <View style={styles.voorbladImage}><Image src={props.image} /></View>
            <View style={styles.headerLogo}><Image src={pdfHeader} /></View>
        </View>
        <View style={styles.content}>
            <Text style={styles.title}>Offerte {props.title}</Text>
            <View>
                <Text style={styles.subtitle}>Offerte voor:</Text>
                <Text style={styles.receiverInfo}>Naam bedrijf</Text>
                <Text style={styles.receiverInfo}>T.a.v.</Text>
                <Text style={styles.receiverInfo}>Straat + huisnummer</Text>
                <Text style={styles.receiverInfo}>Postcode + plaats</Text>
            </View>
        </View>
      </Page>
    </Document>
  );



const Voorbladen = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [isLoading, setIsLoading] = useState(false);
    const environment = process.env.REACT_APP_APP_URL;
    const [data, setData] = useState([]);
    const [divisies, setDivisies] = useState([]);
    const [voorblad, setVoorblad] = useState('');
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [croppedImageSrc, setCroppedImageSrc] = useState('');
    const [pdfImage, setPdfImage] = useState('');
    const [changed, setChanged] = useState(false);

    const selectImage = (file) => {
        setSrc(URL.createObjectURL(file));
        setChanged(false);
    };
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, [])
    const showCroppedImage = async () => {
        try {
          const croppedImage = await getCroppedImage();
          setCroppedImageSrc(croppedImage);
          setPdfImage(croppedImage);
        } catch (error) {
          console.error('Error generating cropped image:', error);
        }
    };
    const getCroppedImage = () => {
        return new Promise((resolve) => {
            const imageElement = document.createElement('img');
            imageElement.src = src;
            const canvas = document.createElement('canvas');
            const scaleX = imageElement.naturalWidth / imageElement.width;
            const scaleY = imageElement.naturalHeight / imageElement.height;
            canvas.width = croppedArea.width;
            canvas.height = croppedArea.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(
              imageElement,
              croppedArea.x * scaleX,
              croppedArea.y * scaleY,
              croppedArea.width * scaleX,
              croppedArea.height * scaleY,
              0,
              0,
              croppedArea.width,
              croppedArea.height
            );
            resolve(canvas.toDataURL('image/jpeg'));
          });
    };

    const setVoorbladImage = async (id) => {
        try {
            const response = await api.put('/divisies/' + voorblad.value, {
                "data": {
                    "afbeelding_voorblad": id
                }
            });
            if (response.data) {
                notifySuccess('Voorblad bijgewerkt en opgeslagen')
            }
        } catch (error) {
            console.log(error);
        }
    }


    const uploadCroppedImage = async () => {
        const fileName = voorblad.label.toLowerCase();
        var formData = new FormData();
        const blob = await fetch(croppedImageSrc).then(response => response.blob());
        formData.append('files', blob, 'voorblad-' + fileName + '.jpg');
        try {
            const response = await api.post('/upload', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                setVoorbladImage(response.data[0].id)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDivisies = async () => {
        try {
            const response = await api.get('/divisies');
            if (response.data) {
                setData(response.data)
                const arrOptions = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.label
                    };
                   arrOptions.push(obj); 
                })
                setDivisies(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getVoorblad = (e) => {
        setVoorblad(e);
        var index = data.findIndex(item => item.id === e.value);
        const imageUrl = environment + '' + data[index].afbeelding_voorblad.url;
        setPdfImage(imageUrl)
    }

    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getDivisies();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/voorbladen" />
        <div className="dashboard-content">
            <DashboardHeader title="Voorbladen" />
            
            <div className="edit-voorblad">

                <div className="voorblad-info">
                    <div className="content">
                        <h3>Kies een divisie</h3>
                        <Select 
                            options={divisies} 
                            className={'select-option'} 
                            placeholder={'Selecteer een divisie'} value={voorblad} 
                            onChange={(e) => getVoorblad(e)} 
                            disabled={isLoading}
                        />
                        {
                            voorblad ?
                            <div className="bestand">
                                <span className="label">Upload hier een nieuwe afbeelding voor dit voorblad</span>
                                <input type="file" accept="image/*" onChange={(e) => { selectImage(e.target.files[0]) }} />
                            </div>
                            :
                            null
                        }
                        

                        {
                            src ?
                            <div className="crop-container">
                                <Cropper
                                    image={src}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={16 / 14.7}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </div>
                            :
                            null
                        }

                    {
                        croppedArea ?
                            <div>
                                <button className="preview-button" onClick={() => {showCroppedImage(); setChanged(true)}}>Toon voorbeeld in PDF</button>
                            </div>
                        :
                        null
                    }
                    {
                        changed ?
                        <button className="save-button" onClick={uploadCroppedImage}>Voorblad opslaan</button>
                        :
                        null
                    }
                        

                    </div>
                </div>

                <div className="preview">
                    {
                        voorblad ?
                        <PDFViewer width={'100%'} height={800}>
                            <VoorbladPreview image={pdfImage} title={voorblad.label} />
                        </PDFViewer>
                        :
                        null
                    }
                    
                </div>

            </div>

            <Toaster />
        </div>
        <BottomNav page="/voorbladen" />
        <img src={vsdvArtwork} className="artwork" />
    </div>
    );
};
export default Voorbladen;