import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import { FunnelChart } from 'chartjs-chart-funnel';
import ChartDataLabels from 'chartjs-plugin-datalabels';

//Helpers
import { formatCurrency } from '../../settings/Helpers';

let chartInstance = null;

const Funnel = ({ data }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const ctx = chartContainer.current.getContext('2d');

      if (chartInstance) {
        chartInstance.destroy();
      }

      const funnelOptions = {
        indexAxis: 'y',
        legend: {
            display: true,
            labels: {
                fontColor: 'white' // You can set the font color for the legend labels here
            }
        },
        plugins: {
          datalabels: {
              color: '#FFFFFF'
          }
        }
    };

      chartInstance = new FunnelChart(ctx, {
        data: {
          labels: data.labels,
          datasets: [
            {
              data: data.data,
              datalabels: {
                // anchor: (context) => {
                //   const value = context.dataset.data[context.dataIndex];
                //   return value < 0.02 ? 'end': 'start';
                // },
                // align: (context) => {
                //   const value = context.dataset.data[context.dataIndex];
                //   return value < 0.02 ? 'end': 'center';
                // },
                // textAlign: 'center',
                // // color: (context) => {
                // //   return '#FFCE56';
                // // },
                font: {
                  size: 12,
                  family: 'PrometoRegular',
                },
                formatter: (value, context) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  const omzetschatting = data.omzetschattingen[context.dataIndex];
                  return value + ' ' + label + ' - ' + formatCurrency(omzetschatting) + '';
                }
              },
              // backgroundColor: ChartFunnel.schemeBlues[9],
            },
          ],
        },
        options: {
          indexAxis: 'y',
        },
        plugins: [ChartDataLabels],
      });
    }
  }, [data]);

  return <canvas ref={chartContainer} />;
};

export default Funnel;