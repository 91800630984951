import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';

//API
import api from '../../../settings/AxiosSetup';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import InfoBlock from '../../../components/dashboard/infoblock';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './dashboard.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Dashboard = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [offertes, setOffertes] = useState('');
    const [allGoedgekeurd, setAllGoedgekeurd] = useState(0);
    const [document, setDocument] = useState('');

    //Function to get medewerker info
    const getMedewerker = async () => {
        try {
            const response = await api.get('/medewerkers/me');
            if (response.data) {
                if (!medewerker) {
                    setMedewerker(response.data);
                }
                localStorage.setItem("medewerker", JSON.stringify(response.data));
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Function to get medewerker info
    const getOffertes = async () => {
        try {
            const response = await api.get('/offertes/offertes-dashboard');
            if (response.data) {
                const excludedKeys = ['afgerond', 'afgewezen', 'in_afwachting', 'in_afwachting_verzenden', 'openstaand'];
                let sum = 0;
                for (const key in response.data) {
                    if (!excludedKeys.includes(key)) {
                        sum += response.data[key];
                    }
                }
                setAllGoedgekeurd(sum);
                setOffertes(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const uploadTest = async (event) => {
        event.preventDefault();
        var formData = new FormData();
        formData.append("files", document);
        try {
            const response = await api.post('/offertes/upload-test', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                console.log(response.data);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }
    
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getMedewerker();
        getOffertes();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/" />
            :
            null
        }
        
        <div className="dashboard-content">
            {
                medewerker ?
                <>
                <DashboardHeader title={'Welkom ' + medewerker.voornaam} />
                    {
                        medewerker && medewerker.gebruiker.role.type === 'authenticated' ?
                        <div className="info-blocks three">
                            <InfoBlock icon={<Icon.Receipt color={'#001E4E'} width={50} height={50} />} title={'Openstaande offertes'} data={offertes} count={offertes.openstaand} page={'/openstaand'} />
                            <InfoBlock icon={<Icon.ClockHistory color={'#BB0024'} width={50} height={50} />} title={'Offertes in afwachting'} data={offertes} count={offertes.in_afwachting} page={'/in-afwachting'} />
                            <InfoBlock icon={<Icon.CheckCircle color={'#00D100'} width={50} height={50} />} title={'Goedgekeurde offertes'} data={offertes} count={allGoedgekeurd} page={'/goedgekeurd'} />
                        </div>
                        :
                        null
                    }
                    <div className="info-blocks two">
                        <InfoBlock icon={<Icon.ClockHistory color={'#FFA500'} width={50} height={50} />} title={'Offertes te verwerken'} data={offertes} count={offertes.te_verwerken} page={'/te-verwerken'} />
                        {
                            medewerker && medewerker.gebruiker.role.type === 'authenticated' ?
                            <InfoBlock icon={<Icon.CheckCircleFill color={'#00D100'} width={50} height={50} />} title={'Offertes volledig verwerkt'} data={offertes} count={offertes.afgerond} page={'/afgerond'} />
                            :
                            null
                        }
                        
                    </div>
                </>
                :
                null
            }
        {/* <form onSubmit={uploadTest}>
            <input type="file" name="upload-pdf" accept="application/pdf" onChange={(e) => setDocument(e.target.files[0])} required />
            <input type="submit" value="uploaden" />
        </form> */}
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Dashboard;