import { View, Text } from '@react-pdf/renderer';

const Inhoudsopgave = (props) => {

    const offerte = props.offerte;
    const groupedPricesFtl = props.groupedPricesFtl;
    const groupedZones = props.groupedZones;
    const pageNumberZones = props.pageNumberZones;
    const pagesToeslagen = props.pagesToeslagen;
    const firstPageToeslagen = props.firstPageToeslagen;
    const itemNumberVrachtToeslagen = props.itemNumberVrachtToeslagen;
    const pagesAlgemeneInformatie = props.pagesAlgemeneInformatie;
    const styles = props.styles;

    return (
       <>
            <Text style={styles.pageTitle}>Inhoudsopgave</Text>
            <View style={styles.inhoudsItem}>
                <Text style={styles.inhoudSubItemHeader}>Voorwoord</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                <Text style={styles.inhoudsPageNumber}>2</Text>
            </View>

            {/* Prijslijst */}
            <View style={styles.inhoudsItem}>
                <Text style={styles.inhoudSubItemHeader}>1. Tarieven</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                <Text style={styles.inhoudsPageNumber}>{4}</Text>
            </View>
            <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                <Text style={styles.inhoudsSubjectHeader}>1.1 Prijslijst</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                <Text style={styles.inhoudsPageNumber}>{4}</Text>
            </View>
            {
                offerte.ftl && offerte.ltl ?
                <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                    <Text style={styles.inhoudsSubjectHeader}>1.2 Prijslijst</Text>
                    <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                    <Text style={styles.inhoudsPageNumber}>{5}</Text>
                </View>
                :
                null
            }
            {
                offerte.zones.length > 0 ?
                <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                    <Text style={styles.inhoudsSubjectHeader}>{offerte.ftl && offerte.ltl ? '1.3 Zones' : '1.2 Zones'}</Text>
                    <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                    <Text style={styles.inhoudsPageNumber}>{pageNumberZones}</Text>
                </View>
                :
                null
            }


            {/* Toeslagen */}
            <View style={styles.inhoudsItem}>
                <Text style={styles.inhoudSubItemHeader}>2. Toeslagen</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                <Text style={styles.inhoudsPageNumber}>{firstPageToeslagen}</Text>
            </View>
            {
                pagesToeslagen.map((item, index) => {
                    return <View key={index}>
                        {
                            item.map((subItem, subIndex) => {
                                return  <View key={subIndex} style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                                            <Text style={styles.inhoudsSubjectHeader}>{'2.' + subItem.itemNumber + '. ' + subItem.titel}</Text>
                                            <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                                            <Text style={styles.inhoudsPageNumber}>{index + firstPageToeslagen}</Text>
                                        </View>
                            })
                        }
                    </View>
                })
            }
            <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                <Text style={styles.inhoudsSubjectHeader}>{'2.' + itemNumberVrachtToeslagen + '. ' + 'Vrachttoeslagen'}</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                <Text style={styles.inhoudsPageNumber}>{pagesToeslagen.length + firstPageToeslagen}</Text>
            </View>
            {
                offerte.overige_afspraken ?
                <View>
                    <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                        <Text style={styles.inhoudsSubjectHeader}>{'Overige afspraken'}</Text>
                        <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                        <Text style={styles.inhoudsPageNumber}>{pagesToeslagen.length + firstPageToeslagen + 1}</Text>
                    </View>
                </View>
                :
                null
            }

            {/* Algemene informatie */}
            <View style={styles.inhoudsItem}>
                <Text style={styles.inhoudSubItemHeader}>3. Algemene informatie</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + firstPageToeslagen) + 1}</Text>
            </View>
            {
                pagesAlgemeneInformatie.map((item, index) => {
                    return <View key={index}>
                        {
                            item.map((subItem, subIndex) => {
                                return  <View key={subIndex} style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                                            <Text style={styles.inhoudsSubjectHeader}>{'3.' + subItem.itemNumber + '. ' + subItem.titel}</Text>
                                            <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                                            <Text style={styles.inhoudsPageNumber}>{index + ((pagesToeslagen.length + firstPageToeslagen) + 1)}</Text>
                                        </View>
                            })
                        }
                    </View>
                    
                })
            }

            {/* Ondertekening */}
            <View style={styles.inhoudsItem}>
                <Text style={styles.inhoudSubItemHeader}>4. Ondertekening</Text>
                <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 1}</Text>
            </View>

            {/* Bijlagen */}
            {
                offerte.bijlagen.length > 0 ?
                <>
                <View style={styles.inhoudsItem}>
                    <Text style={styles.inhoudSubItemHeader}>5. Bijlagen</Text>
                    <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                    <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2}</Text>
                </View>
                {
                    offerte.bijlagen.map((item, index) => {
                    return  <View key={index} style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                                <Text style={styles.inhoudsSubjectHeader}>{item.naam}</Text>
                                <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                                <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2}</Text>
                            </View>
                    })
                }
                {
                    offerte.dieseltoeslag ?
                    <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                        <Text style={styles.inhoudsSubjectHeader}>{offerte.soort_dieseltoeslag.label}</Text>
                        <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                        <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2}</Text>
                    </View>
                    :
                    null
                }
                </>
                :
                <>
                {
                    offerte.dieseltoeslag ?
                    <>
                    <View style={styles.inhoudsItem}>
                        <Text style={styles.inhoudSubItemHeader}>5. Bijlagen</Text>
                        <View style={styles.inhoudsSpace}><View style={styles.dots}></View></View>
                        <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2}</Text>
                    </View>
                    <View style={[styles.inhoudsItem, styles.inhoudSubitem]}>
                        <Text style={styles.inhoudsSubjectHeader}>{offerte.soort_dieseltoeslag.label}</Text>
                        <View style={styles.inhoudsSpace}><View style={styles.dotsSmall}></View></View>
                        <Text style={styles.inhoudsPageNumber}>{(pagesToeslagen.length + pagesAlgemeneInformatie.length + firstPageToeslagen) + 2}</Text>
                    </View>
                    </>
                    :
                    null
                }
                </>
            }

       </>
    );
};
export default Inhoudsopgave;