import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, Link, useSearchParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { ThreeDots } from  'react-loader-spinner';
import Spreadsheet from "react-spreadsheet";

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './prices.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const PrijslijstFtl = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const [params, setParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [offerteNummer, setOfferteNummer] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const offerteId = params.get("id");
    const [offerte, setOfferte] = useState([]);
    const [prices, setPrices] = useState([]);
    const [showTable, setShowTable] = useState(false);

    const delay = ms => new Promise(res => setTimeout(res, ms));
    
    const changePrices = (values, index) => {
        const newPrices = [...prices];
        const currentPrices = [...prices[index].prijzen];
        const differingIndexes = [];
        for (let i = 0; i < values.length; i++) {
            if (values[i] && currentPrices[i]) {
                if (values[i][0].value !== currentPrices[i][0].value) {
                    differingIndexes.push(i);
                }
            }
        }
        if (differingIndexes.length > 1) {
            if (values[differingIndexes[differingIndexes.length - 1]][0].value === '') {
                values[differingIndexes[differingIndexes.length - 1]][0].value = currentPrices[differingIndexes[differingIndexes.length - 1]][0].value;
            }
        }
        newPrices[index].prijzen = values;
        const maxLength = Math.max(...newPrices.map(item => item.prijzen.length));
        newPrices.forEach(item => {
            while (item.prijzen.length < maxLength) {
                item.prijzen.push([{ value: "" }]);
            }
        });
        setPrices(newPrices);
    }


    function formatCurrency(input) {
        if (typeof input === 'number') {
          const roundedValue = Math.round(input * 100) / 100;
          return '€ ' + roundedValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else if (typeof input === 'string') {
          // Check if the string contains any letters
          if (/[^0-9.,]/.test(input)) {
            return input; // Return input as is
          }
      
          const numericValue = parseFloat(input.replace(/[^\d,.]/g, '').replace(',', '.'));
          if (!isNaN(numericValue)) {
            return '€ ' + numericValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          } else {
            return input;
          }
        } else {
          return input;
        }
    }

    const handleFormatting = (updatedPrices) => {
        const current = updatedPrices;
        current.forEach(zone => {
            zone.prijzen = zone.prijzen.map((item, index) => {
            if (index > 0) {
                const headerCell = zone.prijzen[0][0].value.toLowerCase();
                if (headerCell.includes("prijs") || headerCell.includes("tarief") || headerCell.includes("zone")) {
                    if (Array.isArray(item) && item.length > 0 && item[0].value) {
                        const formattedValue = formatCurrency(item[0].value);
                        return [{ value: formattedValue }];
                    } else {
                        return item;
                    }
                } else {
                    return item;
                }
            } else {
                return item;
            }
            });
        });
        return current;
    };

    const calculateAndAddRows = (count) => {
        const rows = [];
        if (count === 0) {
            rows.push([{ "value": "Kolom 1" }]);
            rows.push([{ "value": "" }]);
            return rows;
        }
        for (let a = 0; a < count; a++) {
            rows.push([{ "value": "" }]);
        }
        return rows;
    }

    const addColumn = () => {
        const current = [...prices];
        if (current.length > 7) {
            return notifyError('Je kunt maximaal 7 kolommen toevoegen');
        }
        const newColumn = {
            prijzen: current.length === 0 ? calculateAndAddRows(0) : calculateAndAddRows(current[0].prijzen.length)
        };
        current.push(newColumn);
        setPrices(current);
    }

    const removeColumn = (index) => {
        const current = [...prices];
        current.splice(index, 1);
        setPrices(current);
    }

    const addRow = () => {
        const current = [...prices];
        const updatedPrices = current.map((item) => {
          const updatedPrijzen = [...item.prijzen, [{ value: "" }]];
          return { ...item, prijzen: updatedPrijzen };
        });
        setPrices(updatedPrices);
        return updatedPrices;
    };

    const removeItemsFromPrijzen = (rowIndexes) => {
        const updatedPrices = prices.map((item) => {
          const updatedPrijzen = item.prijzen.filter((_, rowIndex) => !rowIndexes.includes(rowIndex));
          return { ...item, prijzen: updatedPrijzen };
        });
        setPrices(updatedPrices);
        savePrices(updatedPrices);
    };

    const getOfferte = async () => {
        try {
            const response = await api.get('/offertes/' + offerteId);
            if (response.data) {
                setOfferte(response.data);
                setOfferteNummer(response.data.offertenummer);
                setPrices(response.data.prijzen_ftl);
                setShowTable(true);
                setIsLoading(false);
            }
        } catch (error) {}
    }

    const removeEmptyRows = async (arrayPrices) => {
        const arrayIndexes = [];
        arrayPrices.map((item, index) => {
            item.prijzen.map((subItem, subIndex) => {
                const find = arrayIndexes.findIndex(item => item.index === subIndex);
                if (find !== -1) {
                    if (subItem[0].value) {
                        arrayIndexes[find].count++;
                    }
                } else {
                    if (subItem[0].value) {
                        arrayIndexes.push({index: subIndex, count: 1})
                    } else {
                        arrayIndexes.push({index: subIndex, count: 0})
                    }
                }
            })
        })
        const indexesToRemove = [];
        arrayIndexes.map((item, index) => {
            if (item.count === 0) {
                indexesToRemove.push(item.index);
            }
        })
        removeItemsFromPrijzen(indexesToRemove);
    }

    const savePrices = async (updatedPrices) => {
        setIsSaving(true);
        await delay(1000);
        const log = {
            "omschrijving": 'Prijzen bijgewerkt door ' + medewerker.voornaam + ' ' + medewerker.achternaam,
            "datum_tijd": new Date().toISOString()
        };
        offerte.logs.unshift(log);
        const formattedValues = handleFormatting(updatedPrices);
        try {
            const response = await api.post('/offertes/prices/' + offerteId , {
                data: {
                    prijzen_ftl: formattedValues,
                    logs: offerte.logs
                }
            });
            if (response.data) {
                setOfferte(response.data);
                setOfferteNummer(response.data.offertenummer);
                setPrices(response.data.prijzen_ftl);
                setIsSaving(false);
                notifySuccess('Prijzen zijn opgeslagen');
            }
        } catch (error) {}
    }
    
    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOfferte();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/prijslijst-ftl" />
        <div className="dashboard-content">
            <DashboardHeader title="Prijslijst" />
            
            <div className="prices-table">
                <p className="price-tip">TIP: Maak gebruik van de woorden <span>prijs</span>, <span>zone</span> of <span>tarief</span> in de eerste (blauwe) cell om nummers automatisch naar europrijzen te converteren.</p>
                <div className="table-header">
                    <button onClick={addColumn} className="action">Kolom toevoegen</button>
                    {
                        prices && prices.length > 0 ? <button onClick={addRow} className="action">Rij toevoegen</button> : null
                    }
                </div>
            </div>

            <div className="table-contents">
                <div className="table-prices prices-ftl">
                {
                    prices && prices.map((item, index) =>  {
                        const columnWidth = 100 / prices.length;
                        return <div key={index} className="column" style={{width: columnWidth + '%'}}>
                                <div className="actions">
                                    <Icon.Trash onClick={() => removeColumn(index)} className="remove-column" />
                                </div>
                                <Spreadsheet data={item.prijzen} hideRowIndicators hideColumnIndicators onChange={(e) => changePrices(e, index)} />
                            </div>
                    })
                }
                </div>
                
                <div className={'saving-prices ' + (isSaving ? 'show' : '')}>
                    <div className="saving-content">
                        <ThreeDots 
                            height="80" 
                            width="80" 
                            radius="9"
                            color="#001c43" 
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                        <p>Prijzen opslaan</p>
                    </div>
                </div>
            </div>

            <button onClick={() => removeEmptyRows(prices)} className="save">Prijzen opslaan</button>

            <Toaster />
        </div>
        <BottomNav page="/prijslijst-ftl" />
        <img src={vsdvArtwork} className="artwork" />
    </div>
    );
};
export default PrijslijstFtl;