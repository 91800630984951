import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';

//styles
import './infoblock.css';
import 'react-loading-skeleton/dist/skeleton.css';

const InfoBlock = (props) => {

    const navigate = useNavigate();
    const title = props.title;
    const count = props.count ? props.count : 0;

    return (
        <div className="info-block" onClick={() => navigate(props.page)}>
            <div className="info-block-content">
                <span className="icon">{props.icon}</span>
                <div className="info">
                    <span className="count">{props.data ? count : <Skeleton height={25} width={'100%'} style={{marginBottom:10}} />}</span>
                    <h3>{title}</h3>
                </div>
            </div>
        </div>
    );
};
export default InfoBlock;