import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';

//styles
import './bottomnav.css';

const BottomNav = (props) => {

    const navigate = useNavigate();
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));

    const page = props.page;

    const navitems = [
        {
            name: 'Dashboard',
            link: '/',
            icon: <Icon.House />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Openstaand',
            link: '/openstaand',
            icon: <Icon.Receipt />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Goedgekeurd',
            link: '/goedgekeurd',
            icon: <Icon.Receipt />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
        {
            name: 'Menu',
            link: '/menu',
            icon: <Icon.List />,
            roles: ['authenticated', 'controleur', 'super_admin']
        },
    ];

    return (
        <div id="bottomnav">
            <div className="bottom-menu-items">
                    {
                        navitems.map((item, index) =>  {
                            if (item.roles.includes(medewerker.gebruiker.role.type)) {
                                return (
                                    <Link key={index} to={item.link} className={page === item.link ? 'item current' : 'item'}>{item.icon}<span>{item.name}</span></Link>
                                )
                            }
                        })
                    }
                </div>
        </div>
    );
};
export default BottomNav;