import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//Styles
import '../../../index.css';
import '../css/public.css';

//Assets
import vsdvLogo from '../../../assets/images/logo.svg';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//3rd party
import { TailSpin } from  'react-loader-spinner';

const Login = () => {

    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');

    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn === 'true') {
            navigate('/');
        }
    }, []);

    //Variables
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [lostPassword, setLostPassword] = useState(false);
    const [info, setInfo] = useState('Vul hieronder je e-mailadres in en we sturen je een link om je wachtwoord opnieuw in te stellen');
    const [mailSent, setMailSent] = useState(false);

    //Function to handle form submission for authentication
    const handleLogin = async (event) => {
        event.preventDefault();        
        setErrorMessage('');
        setIsLoading(true);
        if (!identifier) {
            setErrorMessage('Vul een e-mailadres in');
            setIsLoading(false);
            return;
        }
        if (!password) {
            setErrorMessage('Vul een wachtwoord in');
            setIsLoading(false);
            return;
        }
        try {
            const response = await api.post('/auth/local', {
                "identifier": identifier,
                "password": password
            });
            if (response.data) {
                localStorage.setItem("bearerToken", response.data.jwt);
                localStorage.setItem("ingelogd", true);
                navigate('/');
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    setErrorMessage('Er is iets fout gegaan (429). Probeer het later nog eens.');
                  break;
                //Default for 400 
                default:
                    setErrorMessage('Onjuiste combinatie van gebruikersnaam/wachtwoord');
            }
        }
        setIsLoading(false);
    }

    //Function to handle form submission for resetting password
    const handlePassword = async (event) => {
        event.preventDefault();
        setErrorMessage('');
        setIsLoading(true);
        if (!identifier) {
            setErrorMessage('Vul een e-mailadres in');
            setIsLoading(false);
            return;
        }
        try {
            const response = await api.post('/auth/forgot-password', {
                "email": identifier
            });
            if (response.data) {
                setInfo('Indien je een account hebt met dit e-mailadres hebben we je zojuist een mail gestuurd met instructies om je wachtwoord opnieuw in te kunnen stellen.');
                setMailSent(true);
                setIsLoading(false);
            }
        } catch (error) {
            
        }
    }
   

    return (
        <div id="login">
            <div className="column login">
                <div className="loginform">
                    <img src={vsdvLogo} alt="VSDV Offertes" />
                    {
                        lostPassword ?
                        <p>{info}</p>
                        :
                        <h3>Inloggen VSDV Offertes</h3>
                    }
                    
                    {
                        !mailSent ?
                        <form id="user-login" className="user-login" onSubmit={lostPassword ? handlePassword : handleLogin}>
                            <input type="text" name="identifier" placeholder="Gebruikersnaam of e-mailadres" onChange={(e) => setIdentifier(e.target.value)} className="form-field" disabled={isLoading} />
                            {
                                lostPassword ?
                                null
                                :
                                <input type="password" name="password" placeholder="Wachtwoord" onChange={(e) => setPassword(e.target.value)} className="form-field" disabled={isLoading} />
                            }
                            {
                                isLoading ?
                                <div className="loader">
                                    <TailSpin
                                        height="50"
                                        width="50"
                                        color="#CB0A32"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                                :
                                <input type="submit" value={lostPassword ? 'Herstel wachtwoord' : 'Inloggen'} className="submit-form" />
                            }
                        </form>
                        :
                        null
                    }
                    
                    {
                        isLoading ?
                        null
                        :
                        <div>
                            <p className="error">{errorMessage}</p>
                            {
                                lostPassword ?
                                <p className="forgot-password" onClick={() => { setLostPassword(false); setMailSent(false); setErrorMessage('') }}>Terug naar inloggen</p>
                                :
                                <p className="forgot-password" onClick={() => { setLostPassword(true); setMailSent(false); setErrorMessage('') }}>Wachtwoord vergeten?</p>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="column image"></div>
            <img src={vsdvArtwork} className="artwork" />
        </div>
      );

  
};
export default Login;