import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

const DoughnutChart = ({ data }) => {
  const [chartData, setChartData] = useState(data);
    useEffect(() => {
      setChartData(data);
    }, [data]);

    if (!data) {
        return null; // If data is not provided, return null to render nothing
    }

    return <Doughnut data={chartData} options={options} />;
};

export default DoughnutChart;
